<template>
  <div class="inline">
    <LazySharedDrawer ref="drawer" @close="onClose">
      <div class="flex flex-col gap-4">
        <h3 class="text-2xl leading-tight">haelsi Vorteilsmitgliedschaft</h3>
        <SharedSavingsCard
          v-if="price && reducedPrice && savings && discount"
          :price="price"
          :reduced-price="reducedPrice"
          :savings="savings"
          :discount="discount" />

        <template v-if="isKidsAppointment === false && isTherapyAppointment === false">
          <MembershipBasic type="yearly" />
          <MembershipRundum type="yearly" />
        </template>
        <template v-if="isTherapyAppointment || isAppointment === false">
          <MembershipPlus type="yearly" />
          <MembershipRundumPlus type="yearly" />
        </template>
        <template v-if="isKidsAppointment || isAppointment === false">
          <MembershipBasicKids type="yearly" />
          <MembershipPlusKids type="yearly" />
        </template>
      </div>
    </LazySharedDrawer>
  </div>
</template>

<script setup lang="ts">
const membershipDrawerStore = useMembershipDrawerStore();
const price = computed(() => membershipDrawerStore.price);
const discount = computed(() => membershipDrawerStore.discount);
const isKidsAppointment = computed(() => isPediatricsDepartment(membershipDrawerStore.department));
const isTherapyAppointment = computed(() => hasHigherDiscount(membershipDrawerStore.department));
const isAppointment = computed(() => membershipDrawerStore.department !== undefined);

const reducedPrice = computed(() =>
  price.value && discount.value ? roundToPrecision(price.value * (1 - discount.value / 100), 2) : undefined,
);
const savings = computed(() =>
  price.value && reducedPrice.value ? roundToPrecision(price.value - reducedPrice.value, 2) : undefined,
);

membershipDrawerStore.$subscribe(
  (_mutation, state) => {
    if (state.isVisible === true) {
      handleOpen();
    }
  },
  { detached: true },
);
const drawer = ref();
function handleOpen(): void {
  drawer.value?.open();
}
function onClose(): void {
  membershipDrawerStore.close();
}
</script>

<style scoped></style>
