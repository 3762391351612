<template>
  <SharedMembershipBox :price="price" title="haelsi Basic" :link="link" :type="type">
    <MembershipBaseList class="text-sm text-gray-600" />
  </SharedMembershipBox>
</template>

<script setup lang="ts">
import { Membership } from "~/utils/enum/Membership";
import { createMembershipLink, getMembershipPrice } from "~/utils/membership";

const props = withDefaults(
  defineProps<{
    type?: "monthly" | "yearly";
    quickCheckout?: boolean;
  }>(),
  {
    quickCheckout: true,
    type: "yearly",
  },
);

const price = getMembershipPrice(Membership.BASIC, props.type);
const link = createMembershipLink(Membership.BASIC, props.type, "quick_checkout");
</script>

<style scoped></style>
