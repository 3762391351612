<template>
  <div class="flex flex-col gap-4 rounded-2xl border border-gray-200 px-4 py-6">
    <div class="flex items-center justify-between">
      <p class="text-lg text-gray-700">{{ title }}</p>
      <AppPill v-if="highlight" class="bg-lavender font-medium">&#9825; beliebt </AppPill>
    </div>
    <div>
      <div>
        <p class="flex items-center gap-1 text-3xl font-normal leading-normal">
          {{ transformToCurrency(price) }} <span class="text-xs text-gray-600">/ Monat</span>
        </p>
      </div>
      <div class="mt-2">
        <slot />
      </div>
    </div>
    <div>
      <AppButton :to="link">{{ buyMembershipButtonText }}</AppButton>
      <p v-if="type === 'monthly'" class="mt-2 text-center text-xs text-brick-700">monatlich kündbar</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "~/store/global";

defineProps<{
  price: number;
  title: string;
  link: string;
  highlight?: boolean;
  type: "monthly" | "yearly";
}>();

const { buyMembershipButtonText } = useGlobalStore();
</script>

<style scoped></style>
